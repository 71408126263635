import { Link } from "gatsby";
import React from "react";

export default function Page404(): JSX.Element {
  return (
    <p>
      Page not found <Link to="/">click here to go back</Link>
    </p>
  );
}
